const API_URL = `${process.env.GATSBY_API_URL || ''}/careers`

type SendCareerFormPropTypes = FormData

const sendCareerForm = async (data: SendCareerFormPropTypes): Promise<void> => {
  await fetch(API_URL, {
    method: 'POST',
    redirect: 'follow',
    body: data,
  })
}

export default sendCareerForm
