import React, { ReactElement } from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import './PopupModal.scss'
import avidaLogo from '../../assets/svg/avida-logo.svg'
import closeIcon from '../../assets/svg/close.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component'

type ModalPropTypes = {
  isShowing: boolean
  onClose(): void
  header: string
  body: string
  footer?: string
  contactList?: string[]
  footerLink?: boolean
}

const PopupModal = ({
  isShowing,
  onClose,
  header,
  body,
  footer,
  contactList,
  footerLink,
}: ModalPropTypes): ReactElement | null =>
  isShowing
    ? ReactDOM.createPortal(
        <>
          <div className="pop-up-dim-overlay" />

          <Helmet>
            <body className="modal-open" />
          </Helmet>

          <div className="pop-up" data-testid="pop-up">
            <div className="pop-up-content">
              <button
                type="button"
                onClick={onClose}
                className="pop-up-content-close"
              >
                <img src={closeIcon} alt="close icon" />
              </button>
              <LazyLoadImage
                src={avidaLogo}
                alt="logo"
                className="pop-up-content-logo"
              />
              <div className="pop-up-content-main">
                <h3>{header}</h3>
                <p>{body}</p>
                {!!footer && <p>{footer}</p>}
                {!!contactList && (
                  <ul className="pop-up-content-main-contact-list">
                    {contactList.map((contact) => (
                      <li key={contact}>{contact}</li>
                    ))}
                  </ul>
                )}
                {!!footerLink && (
                  <Link to="/about-us/" className="pop-up-content-main-link">
                    Click here to know more about Avida Land.
                  </Link>
                )}
              </div>
            </div>
          </div>
        </>,
        document.body,
      )
    : null

export default PopupModal
