/* eslint-disable no-param-reassign */
import {
  hasValue,
  isEmail,
  isPhoneWithPrefix,
} from '../../services/validations'

type FieldTypes = { [key: string]: string }

type FieldValidationTypes = {
  values: FieldTypes
  fieldName: string
  errors: FieldTypes
}

const validateFirstName = ({
  values,
  fieldName,
  errors,
}: FieldValidationTypes): void => {
  const fieldValue = values[fieldName]

  if (!hasValue(fieldValue)) {
    errors[fieldName] = 'First name field is required.'
  }
}

const validateLastName = ({
  values,
  fieldName,
  errors,
}: FieldValidationTypes): void => {
  const fieldValue = values[fieldName]

  if (!hasValue(fieldValue)) {
    errors[fieldName] = 'Last name field is required.'
  }
}

const validatePhone = ({
  values,
  fieldName,
  errors,
}: FieldValidationTypes): void => {
  const fieldValue = values[fieldName]

  if (!hasValue(fieldValue)) {
    errors[fieldName] = 'Phone field is required.'
    return
  }

  if (!isPhoneWithPrefix(fieldValue)) {
    errors[fieldName] = 'Please input a phone number.'
  }
}

const validateEmail = ({
  values,
  fieldName,
  errors,
}: FieldValidationTypes): void => {
  const fieldValue = values[fieldName]

  if (!hasValue(fieldValue)) {
    errors[fieldName] = 'Email field is required.'
    return
  }

  if (!isEmail(fieldValue)) {
    errors[fieldName] = 'Please input an email address.'
  }
}

const validateResume = ({
  values,
  fieldName,
  errors,
}: FieldValidationTypes): void => {
  const fieldValue = values[fieldName]

  if (!hasValue(fieldValue)) {
    errors[fieldName] = 'Resume field is required.'
  }
}

const CareerPostForm = (values: {}): {} => {
  const errors = {}

  validateFirstName({ values, fieldName: 'firstName', errors })
  validateLastName({ values, fieldName: 'lastName', errors })
  validatePhone({ values, fieldName: 'contactNumber', errors })
  validateEmail({ values, fieldName: 'email', errors })
  validateResume({ values, fieldName: 'resume', errors })

  return errors
}

export default CareerPostForm
