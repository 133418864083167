import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import './CareerPostForm.scss'
import { useLocation } from '@reach/router'
import COUNTRY_LIST from '../../data/country-list.json'
import Form, {
  FormContent,
  FormInput,
  FormInputWithPrefix,
  useFormState,
} from '../Form/Form'
import CareerPostFormValidation from './CareerPostForm.validation'
import FileUpload from '../FileUpload/FileUpload'
import PopupModal from '../PopupModal/PopupModal'
import sendCareerForm from '../../services/sendCareerForm'

import {
  alphaOnly,
  numericOnly,
  preventInput,
  setIsIframe,
} from '../Validators/InputValidations'

const PHONE_PREFIX_OPTIONS = COUNTRY_LIST.filter(
  (country) => country.Dial === '63',
).map((country) => ({
  label: `${country.Unicode} ${country.Dial}`,
  value: country.Dial,
}))

type ContactUsFormPropTypes = {
  position: string
  location: string
}

const CareerPostForm = ({
  position,
  location,
}: ContactUsFormPropTypes): ReactElement => {
  const {
    isFormDisplayed,
    values,
    errors,
    onFieldValueChange,
    onFieldBlur,
    onFileUpload,
    validateFields,
    resetFields,
  } = useFormState({
    validation: CareerPostFormValidation,
  })
  const currentLocation = useLocation()
  const fileRef = useRef<HTMLInputElement>(null)
  const [captcha, setCaptcha] = useState({})
  const [isSuccessShown, setIsSuccessShown] = useState<boolean>(false)
  const [isAgreeChecked, setIsAgreeChecked] = useState<boolean>(false)
  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState<boolean>(false)

  const getDepartment = (url: string): string => {
    // eslint-disable-next-line no-nested-ternary
    return url.includes(`/careers/corporate/`)
      ? 'Corporate'
      : url.includes(`/careers/sales/`)
      ? 'Sales'
      : ''
  }

  const handleSubmit = useCallback(async () => {
    if (isAgreeChecked && isRecaptchaChecked) {
      const fieldErrors = validateFields(values)

      if (!Object.keys(fieldErrors).length) {
        try {
          values.brandId = process.env.GATSBY_API_BRAND_ID || ''
          values.position = position
          values.department = getDepartment(currentLocation.pathname)
          const formData = new FormData()
          const file = fileRef.current?.files?.length
            ? fileRef.current?.files[0]
            : ''
          const fileSize = fileRef.current?.files?.length
            ? fileRef.current?.files[0].size
            : ''
          Object.entries(values).forEach(([key, value]) => {
            formData.append(key, value)
          })

          const fileNum = fileSize ? fileSize / 1024 / 1024 : 0

          if (fileNum <= 5) {
            formData.append('resume', file)
            await sendCareerForm(formData)

            resetFields()
            setIsAgreeChecked(false)
            setIsSuccessShown(true)
          }
        } catch (e) {
          setIsSuccessShown(false)
        }
      }
    }
  }, [
    position,
    isAgreeChecked,
    isRecaptchaChecked,
    setIsSuccessShown,
    validateFields,
    resetFields,
    values,
    currentLocation.pathname,
  ])

  useEffect(() => {
    setIsIframe(false)
    const formFields: any = {
      firstNameInput: document.getElementById('firstName'),
      lastNameInput: document.getElementById('lastName'),
      middleNameInput: document.getElementById('middleName'),
      contactNumberInput: document.getElementById('contactNumber'),
    }

    // Form Input paste event
    Object.keys(formFields).forEach((key) =>
      formFields[key].addEventListener('paste', preventInput, true),
    )
  }, [])

  return (
    <>
      <div className="career-post-form-separator" />
      <Form onSubmit={handleSubmit} display={isFormDisplayed}>
        <div className="career-post-form">
          <h3 className="career-post-form-position">{position}</h3>
          <p className="career-post-form-location">{location}</p>
          <FormInput
            id="firstName"
            name="firstName"
            placeholder="First Name"
            onChange={alphaOnly}
            onBlur={onFieldBlur}
            error={errors.firstName}
          />
          <FormInput
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            onChange={alphaOnly}
            onBlur={onFieldBlur}
            error={errors.lastName}
          />
          <FormInput
            id="middleName"
            name="middleName"
            placeholder="Middle Name"
            onChange={alphaOnly}
            onBlur={onFieldBlur}
            error={errors.middleName}
          />
          <FormInput
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            onChange={onFieldValueChange}
            onBlur={onFieldBlur}
            error={errors.email}
          />
          <FormInputWithPrefix
            id="contactNumber"
            name="contactNumber"
            placeholder="Phone"
            onInput={numericOnly}
            onBlur={onFieldBlur}
            prefixList={PHONE_PREFIX_OPTIONS}
            error={errors.contactNumber}
            prefixLarge
          />
          <FileUpload
            id="resume"
            name="resume"
            forwardedRef={fileRef}
            onChange={onFileUpload}
            error={errors.resume}
          />
          <FormContent>
            <div className="career-post-form-footer">
              <div className="career-post-form-checkboxes">
                <div className="career-post-form-i-agree">
                  <label htmlFor="i-agree">
                    <input
                      id="i-agree"
                      type="checkbox"
                      checked={isAgreeChecked}
                      readOnly
                    />
                    <span
                      role="checkbox"
                      onClick={(event): void => {
                        event.currentTarget.blur()
                        setIsAgreeChecked(!isAgreeChecked)
                      }}
                      onKeyPress={(event): void => {
                        if (event.which === 13) {
                          setIsAgreeChecked(!isAgreeChecked)
                        }
                      }}
                      tabIndex={0}
                      aria-checked={isAgreeChecked}
                      aria-labelledby="i-agree"
                      data-testid="policy"
                    />
                  </label>
                  <div className="career-post-form-policy-text">
                    I understand and agree with the{' '}
                    <a href="/privacy-notice/" target="_blank">
                      Privacy Notice
                    </a>{' '}
                    and{' '}
                    <a href="/terms-and-conditions/" target="_blank">
                      Terms & Conditions
                    </a>
                  </div>
                </div>
                <div className="career-post-form-recaptcha">
                  <ReCAPTCHA
                    sitekey={process.env.GATSBY_RECAPTCHA_API_KEY || ''}
                    onChange={(value: string | null): void => {
                      setIsRecaptchaChecked(!!value)
                    }}
                    ref={(e): void => setCaptcha(e ?? {})}
                  />
                </div>
              </div>
            </div>
          </FormContent>
        </div>
      </Form>
      <div className="career-post-form-button">
        <button
          type="button"
          onClick={handleSubmit}
          className="career-post-form-submit-button"
          disabled={!isAgreeChecked || !isRecaptchaChecked}
        >
          Submit
        </button>
      </div>
      <PopupModal
        isShowing={isSuccessShown}
        onClose={(): void => {
          setIsSuccessShown(false)
        }}
        header="Thank you for applying. "
        body="Our recruitment team will get in touch with you should you be shortlisted for the position."
        footerLink
      />
    </>
  )
}

export default memo(CareerPostForm)
