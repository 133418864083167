import React, { ReactElement } from 'react'
import { Link } from 'gatsby'
import { Location } from '@reach/router'
import './MiniCareerCard.scss'

type MiniCareerCardPropTypes = {
  position: string
  team?: string
  date: string
  slug: string
  className?: string
}

const MiniCareerCard = ({
  position,
  team,
  date,
  slug,
  className,
}: MiniCareerCardPropTypes): ReactElement => {
  return (
    <Location>
      {({ location }) => {
        const loc = location.pathname
        const url = loc.split('/')[2]
        return (
          <Link
            to={`/careers/${url}/${slug}/`}
            className="mini-career-article-card-link"
          >
            <div
              className={`mini-career-article-card${
                className ? ` ${className}` : ''
              }`}
            >
              <div className="mini-career-article-card-details">
                <span>{team}</span>
                <h3>{position}</h3>
                <p>{date}</p>
              </div>
            </div>
          </Link>
        )
      }}
    </Location>
  )
}

export default MiniCareerCard
