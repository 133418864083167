import { Location } from '@reach/router'
import { Link } from 'gatsby'
import React, { memo, ReactElement, useState } from 'react'
import CaretTriangleIcon from '../../assets/svg/caret-triangle-red.svg'
import './VerticalNavigation.scss'

export type NavItemTypes = {
  url?: string
  label: string
  items?: NavItemTypes[]
}

type VerticalNavigationPropTypes = {
  className?: string
  variant?: 'primary' | 'secondary'
  navigations: NavItemTypes[]
}

const VerticalNavigationItem = ({
  url,
  label,
  items,
}: NavItemTypes): ReactElement => {
  const [isSubOpened, setIsSubOpened] = useState(false)

  return (
    <Location>
      {({ location }) => {
        const loc = location.pathname
        const path = loc.split('/')[1]
        return (
          <li key={label}>
            {url ? (
              <Link
                to={`/${
                  path === 'neighborhoods' || path === 'estates'
                    ? `${path}/`
                    : ''
                }${url}/`}
              >
                {label}
              </Link>
            ) : (
              <button
                type="button"
                onClick={(): void => setIsSubOpened(!isSubOpened)}
                className={isSubOpened ? 'active' : ''}
              >
                {label}
                <img src={CaretTriangleIcon} alt="caret icon" />
              </button>
            )}
            {!!items && items.length && (
              <ul
                style={{
                  height: `${isSubOpened ? items.length * 30 + 10 : 0}px`,
                }}
              >
                {items.map((item) => (
                  <li key={item.label}>
                    <Link to={`/${item.url}/`}>{item.label}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        )
      }}
    </Location>
  )
}

const VerticalNavigation = ({
  className,
  variant = 'primary',
  navigations,
}: VerticalNavigationPropTypes): ReactElement => {
  return (
    <ul
      className={`${
        className ? `${className} ` : ''
      }vertical-navigation ${variant}`}
    >
      {navigations.map(({ url, label, items }) => (
        <VerticalNavigationItem
          key={label}
          url={url}
          label={label}
          items={items}
        />
      ))}
    </ul>
  )
}

export default memo(VerticalNavigation)
