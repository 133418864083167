import React, { ReactElement, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import './CareerOpportunity.scss'
import Moment from 'react-moment'

import Layout from '../../layouts/Layout/Layout'
import { CareerTypes } from '../CareerCorporate/CareerCorporate'

import ArticleCareerLayout from '../../layouts/ArticleCareerLayout/ArticleCareerLayout'
import CareerBanner from '../../assets/images/main-career-page-banner.jpg'
import SideFormModal from '../../components/SideFormModal/SideFormModal'
import CareerPostForm from '../../components/CareerPostForm/CareerPostForm'
import Seo from '../../components/Seo/Seo'
import { removeChatbot, showChatbot } from '../../services/snapengage'

type MainPropTypes = {
  data: {
    careerData: CareerTypes
    recentArticles: {
      nodes: CareerTypes[]
    }
  }
  pageContext: any
}

const asideNavItems = [
  {
    url: 'careers/corporate/',
    label: 'Corporate Team',
    category: 'Corporate Team',
  },
  {
    url: 'careers/sales/',
    label: 'Sales Team',
    category: 'Sales Team',
  },
  {
    url: 'careers/broker-accreditation/',
    label: 'Broker Accreditation',
    category: 'Broker Accreditation',
  },
]

const CareerOpportunity = ({
  data: {
    careerData,
    recentArticles: { nodes: recentArticles },
  },
  pageContext,
}: MainPropTypes): ReactElement => {
  const [showModal, setShowModal] = useState(false)

  const postedDate = <Moment fromNow>{careerData.postedDate}</Moment>
  useEffect((): any => {
    removeChatbot()

    return (): void => {
      showChatbot()
    }
  }, [])

  return (
    <Layout navButtonVariant="back">
      <Seo
        title={careerData.title}
        ogMetaData={{
          title: careerData.title,
          description: careerData.description,
          image: CareerBanner,
        }}
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/careers/${pageContext.filterType}/${careerData.slug}/`,
          description: careerData.description,
          keywords: careerData.tags.join(', '),
        }}
      />
      <ArticleCareerLayout
        title="Career Opportunities"
        asideNavItems={asideNavItems}
        asideArticleTitle="Open Positions"
        recentArticles={recentArticles}
        contentClassName="career-opportunity "
      >
        <article className="career-opportunity">
          <img
            src={CareerBanner}
            alt="banner"
            className="career-opportunity-banner"
          />
          <div className="career-opportunity-container">
            <div className="career-opportunity-header">
              <div className="career-opportunity-header-container">
                <h3 className="career-opportunity-header-container-title">
                  Career Opportunity
                </h3>
                <h2 className="career-opportunity-header-container-position">
                  {careerData.title}
                </h2>
                <h2 className="career-opportunity-header-container-location">
                  {careerData.location} <span>•</span> Posted {postedDate}
                  <span>•</span> Apply Before {careerData.applyBeforeDate}
                </h2>
              </div>
              <div className="career-opportunity-button">
                <button type="button" onClick={(): void => setShowModal(true)}>
                  Apply Here
                </button>
              </div>
            </div>
            <div className="career-opportunity-body">
              <h3 className="career-opportunity-body-title">Job Description</h3>
              <ul className="career-opportunity-body-list">
                {ReactHtmlParser(careerData.jobDescription)}
              </ul>
              <div className="career-opportunity-body-separator" />
            </div>
            <div className="career-opportunity-body">
              <h3 className="career-opportunity-body-title">Qualifications</h3>
              <ul className="career-opportunity-body-list">
                {ReactHtmlParser(careerData.qualifications)}
              </ul>
            </div>
            <div className="career-opportunity-button">
              <button type="button" onClick={(): void => setShowModal(true)}>
                Apply Here
              </button>
            </div>
            {showModal && (
              <div className="career-opportunity-container-modal">
                <SideFormModal onClose={(): void => setShowModal(false)}>
                  <CareerPostForm
                    position={careerData.title}
                    location={careerData.location}
                  />
                </SideFormModal>
              </div>
            )}
          </div>
        </article>
      </ArticleCareerLayout>
    </Layout>
  )
}
export default CareerOpportunity

export const pageQuery = graphql`
  query CareerQuery($id: String!, $employmentTypeName: String) {
    careerData: career(id: { eq: $id }) {
      ...CareerOpportunityPageMainFields
    }
    recentArticles: allCareer(
      limit: 3
      filter: { employmentType: { name: { eq: $employmentTypeName } } }
    ) {
      nodes {
        ...CareerFields
      }
    }
  }
`
