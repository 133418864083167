import React, {
  memo,
  ReactElement,
  Ref,
  useCallback,
  useState,
} from 'react'
import { FormUploadPropTypes } from '../Form/Form'
import './FileUpload.scss'

type FileUploadPropTypes = {
  id?: string
  name?: string
  error?: string
  onChange?({ fieldName, dataName }: FormUploadPropTypes): void
  forwardedRef?: Ref<HTMLInputElement>
}

const FileUpload = ({ name, error, onChange, forwardedRef }: FileUploadPropTypes): ReactElement => {
  const [file, setFile] = useState('')
  const [fileName, setFileName] = useState('')
  const [fileSize, setFileSize] = useState(0)

  const photoUpload = useCallback(({ e, name: fieldName, onChange }): void => {
    e.preventDefault()
    const reader = new FileReader()
    const dataName = e.target.files[0].name
    const dataFile = e.target.files[0]
    const dataSize = e.target.files[0].size
    setFileName(dataName)
    setFileSize(dataSize)
    reader.onloadend = (): void => {
      setFile(dataFile)
    }
    reader.readAsDataURL(dataFile)
    onChange && onChange({ fieldName, dataName })
  }, [])

  const fileNum = fileSize ? fileSize / 1024 / 1024 : 0
  const sizeError =
    fileNum > 5 ? 'Attachment is too large, 5MB maximum file size.' : ''

  return (
    <>
      <div className="file-upload">
        <input
          className={`file-upload-input ${error ? ' has-error' : ''}`}
          value={`${!fileName ? 'Resume Attachment' : fileName}`}
        />
        <div
          className={`file-upload-container file-upload-button ${
            error ? ' has-error' : ''
          }`}
        >
          <span>UPLOAD</span>
          <input
            ref={forwardedRef}
            id="uploadBtn"
            type="file"
            className="upload"
            accept=".doc,.docx,.pdf"
            onChange={e => photoUpload({ e, name, onChange })}
          />
        </div>
        <p className="form-field-error">{error || sizeError}</p>
      </div>
      <p className="file-upload-text">
        .doc, .docx, PDF file only Max. upload size 5MB
      </p>
    </>
  )
}

const forwardedRef = (props: FileUploadPropTypes): ReactElement => <FileUpload {...props} />

export default memo(forwardedRef)
