import React, { ReactElement, memo } from 'react'
import './ArticleCareerLayout.scss'
import MiniCareerCard from '../../components/MiniCareerCard/MiniCareerCard'
import Breadcrumbs, {
  BreadcrumbsTypes,
} from '../../components/Breadcrumbs/Breadcrumbs'
import ContentNavigation, {
  ContentNavigationTypes,
} from '../../components/ContentNavigation/ContentNavigation'
import VerticalNavigation, {
  NavItemTypes,
} from '../../components/VerticalNavigation/VerticalNavigation'
import { CareerTypes } from '../../templates/CareerCorporate/CareerCorporate'

type ArticleLayoutPropTypes = {
  title?: string
  asideNavItems: NavItemTypes[]
  breadcrumbs?: BreadcrumbsTypes[]
  contentNavigation?: {
    navigations: ContentNavigationTypes[]
    navigationPrefix: string
    activeNavigation: string
  }
  recentArticles: CareerTypes[]
  className?: string
  contentClassName?: string
  asideArticleTitle?: string
  children?:
    | string
    | Element
    | ReactElement
    | boolean
    | (string | Element | ReactElement | boolean)[]
}

const ArticleCareerLayout = ({
  title,
  asideNavItems,
  breadcrumbs,
  contentNavigation,
  recentArticles,
  className,
  contentClassName,
  asideArticleTitle,
  children,
}: ArticleLayoutPropTypes): ReactElement => {
  return (
    <div
      className={`${
        className ? `${className} ` : ''
      }article-career-layout-container`}
    >
      <aside className="article-career-layout-aside">
        <div className="article-career-layout-aside-header">
          {!!title && (
            <h3 className="article-career-layout-aside-header-title">
              {title}
            </h3>
          )}
          <VerticalNavigation
            navigations={asideNavItems}
            className="article-career-layout-aside-header-links"
          />
        </div>
        <div className="article-career-layout-recent-articles">
          {!asideArticleTitle ? (
            <h3 className="article-career-layout-recent-articles-title">
              Recent Post
            </h3>
          ) : (
            <h3 className="article-career-layout-recent-articles-title">
              {asideArticleTitle}
            </h3>
          )}
          {recentArticles.map(
            ({
              id,
              slug,
              title: articleTitle,
              employmentType,
              applyBeforeDateFull,
            }) => (
              <MiniCareerCard
                key={id}
                slug={slug}
                team={employmentType.name}
                position={articleTitle}
                date={applyBeforeDateFull}
                className="item"
              />
            ),
          )}
        </div>
      </aside>
      <main className="article-career-layout-main">
        {!!contentNavigation && (
          <ContentNavigation
            navigationPrefix={contentNavigation.navigationPrefix}
            navigations={contentNavigation.navigations}
          />
        )}
        <div
          className={`article-career-layout-main-content${
            contentClassName ? ` ${contentClassName}` : ''
          }`}
        >
          {!!breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
          {children}
        </div>
      </main>
    </div>
  )
}

export default memo(ArticleCareerLayout)
