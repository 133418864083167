import React, { ReactElement } from 'react'
import { Link } from 'gatsby'
import './Breadcrumbs.scss'

export type BreadcrumbsTypes = {
  url?: string
  label: string
}

type BreadcrumbsPropTypes = {
  items: BreadcrumbsTypes[]
  className?: string
}

const Breadcrumbs = ({
  items,
  className,
}: BreadcrumbsPropTypes): ReactElement => {
  return (
    <ul className={`breadcrumbs${className ? ` ${className}` : ''}`}>
      <li>
        <Link to="/">Home</Link>
      </li>
      {items.map((item) => (
        <li key={item.label}>
          {item.url ? (
            <Link to={`/${item.url}/`}>{item.label}</Link>
          ) : (
            <span>{item.label}</span>
          )}
        </li>
      ))}
    </ul>
  )
}

export default Breadcrumbs
